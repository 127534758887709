import { useLocation } from "react-router";
import TournamentPlayer from "../../type/TournamentPlayer";
import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  Link,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";
import biljartTafelLeeg from "../../assets/images/biljarttafelLeeg.png";
import biljartTafelLeegVert from "../../assets/images/biljarttafelLeegVerticaal.png";
import { EmailIcon } from "@chakra-ui/icons";
import { ReactNode } from "react";

type SuccessPageState = {
  tournamentplayer: TournamentPlayer;
};

// Declaration of the StyledListItem component
const StyledListItem: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    // <ListItem ml={"4px"}>
    <Text fontFamily={"Arial"} fontWeight={900}>
      {children}
      <br />
      <br />
    </Text>
    // </ListItem>
  );
};

export function SuccessPage() {
  const location = useLocation();
  const tournamentplayer = (location.state as SuccessPageState)
    .tournamentplayer;

  return (
    <>
      <Center>
        <Box
          backgroundColor="#139D08"
          width={{
            base: "326px",
            sm: "422px",
            md: "634px",
            lg: "845px",
            xl: "1056px",
          }}
          pb="10px"
          mt="20px"
          p="20px"
        >
          <Heading textAlign={"center"} pb={3}>
            Bedankt {tournamentplayer.firstname} {tournamentplayer.lastname},
          </Heading>
          {/* <List spacing={3} paddingLeft="20px" styleType="disc"> */}
          <StyledListItem>
            We hebben je inschrijving goed ontvangen.
          </StyledListItem>

          <StyledListItem>Het inschrijvingsgeld bedraagt €20.</StyledListItem>
          <StyledListItem>
            Om alles in goede banen te leiden en iedereen een gelijke kans te
            geven op deelname, vragen wij je om je inschrijvingsgeld van 20 euro
            te storten op rekeningnummer BE08 1030 7303 5513 vóór 14 augustus 2024
            met vermelding van deze mededeling: Biljart + je volledige naam.
            Deze stap zorgt ervoor dat we het prijzengeld kunnen garanderen én
            dat we niemand onnodig op de wachtlijst hoeven te zetten.
          </StyledListItem>
          <StyledListItem>
            LET OP: JE INSCHRIJVING IS PAS DEFINITIEF NA ONTVANGST VAN JE
            INSCHRIJVINGSGELD OP BOVENVERMELDE BANKREKENING.
          </StyledListItem>
          <StyledListItem>
            Mocht je je toch bedenken en willen uitschrijven, dan kunnen we je
            inschrijvingsgeld alleen terugstorten als je dit vóór 14 augustus doet.
            Uitschrijven na deze datum betekent helaas dat het inschrijvingsgeld
            niet kan worden teruggegeven, tenzij een andere enthousiaste speler
            je plaats inneemt.
          </StyledListItem>
          <StyledListItem>
            Dit prijzengeld wordt verdeeld onder de eerste 4 winnaars.
          </StyledListItem>
          <StyledListItem>
            Wedstrijdreglement volgens International 8-ball rules. Samenvatting
            van het reglement:{" "}
            <Link
              href="/assets/files/Samenvatting_regels_competitie_2024.pdf"
              target={"_blank"}
            >
              KLIK HIER
            </Link>{" "}
            <br />
            (Volledig reglement, zie 'Reglement' in menu)
          </StyledListItem>
          <StyledListItem>
            Verdere info of vragen over je inschrijving:{" "}
            <EmailIcon boxSize="1em" mr="2" />
            <Link
              href="mailto:deschevestoot@hotmail.com?subject=Toernooi%202024-08-24"
              color="white>"
            >
              deschevestoot@hotmail.com
            </Link>
          </StyledListItem>
          {/* </List> */}
        </Box>
      </Center>
    </>
  );
}
