import {
  Button,
  Flex,
  FormControl,
  Input,
  HStack,
  VStack,
  Text,
  Grid,
  useBreakpointValue 
} from "@chakra-ui/react";
import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from "react-router";
import ErrorMessage from "./ErrorMessage";
import {tournamentPlayerRegister} from "../../service/tournamentplayer";
import {getTournamentRegisteredPlayersCount} from "../../service/tournament";
import biljartTafelLeeg from "../../assets/images/biljarttafelLeeg.png";
import biljartTafelLeegVert from "../../assets/images/biljarttafelLeegVerticaal.png";


export function TournamentForm() {
  const navigate = useNavigate();
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState<string | null>("");

  // voorlopig hardcoded
  const tournament_id: number = 4;

  const bgImage = useBreakpointValue({
    base: biljartTafelLeegVert,
    sm: biljartTafelLeeg,
  });

  const isGridVisible = bgImage === biljartTafelLeeg;


  //useEffect to get the number of players registered for the tournament
  const [numberOfPlayers, setNumberOfPlayers] = useState(0);
  const [maxPlayers, setMaxPlayers] = useState(0);

  const fetchTournamentInfo = useCallback(async () => {
    try {
      const response = await getTournamentRegisteredPlayersCount(tournament_id);
      setNumberOfPlayers(response.tournament_players_already_registered);
      setMaxPlayers(response.tournament_max_players);
    } catch (error: any) {
      console.error(error);
    }
  }, [tournament_id]);

  useEffect(() => {
    fetchTournamentInfo();
  }, [fetchTournamentInfo]);

  // log the updated values
  useEffect(() => {
    console.log("aantal ingeschreven spelers: " + numberOfPlayers);
    console.log("max aantal spelers: " + maxPlayers);
  }, [numberOfPlayers, maxPlayers]);


  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    try {
      const savedPlayer = await tournamentPlayerRegister(firstname, lastname, email, phone, tournament_id);
      navigate("/success", { state: { tournamentplayer: savedPlayer } });
    } catch (errorResponse: any) {
      console.error(errorResponse);
      // Check if the errorResponse has a 'data' property and extract the error message
      const errorMessage = errorResponse?.data?.error || "An unknown error occurred.";
  
      // Check if the error message contains 'ER_DUP_ENTRY' and set a custom message
      const customErrorMessage = errorMessage.includes("ER_DUP_ENTRY")
        ? "Een speler met deze naam of e-mailadres is al geregistreerd."
        : errorMessage;
  
      setError(customErrorMessage);
    }
  }

  return (
    <>
    {numberOfPlayers >= maxPlayers ? (
       <VStack>
        <Text fontFamily={"Arial"} fontWeight={900} pl={4} pr={4}>Toernooi enkelspel.</Text>
        <Text fontFamily={"Arial"} fontWeight={900} pl={4} pr={4}>24 augustus 2024, 16u00. The Corner, Ninove.</Text>
        <Text fontFamily={"Arial"} fontWeight={900} pl={4} pr={4}>Max aantal spelers: {maxPlayers}. Aantal vrije plaatsen: {maxPlayers - numberOfPlayers}.</Text>
        <Text fontFamily={"Arial"} fontWeight={900} pl={4} pr={4}>Inschrijvingen gesloten.</Text>
        </VStack>
      ) : (<>
      <form id="Register" onSubmit={handleSubmit} style={{ height: "100%" }}>
        <Flex
          h="100%"
          align={{ base: "start", sm: "center" }}
          justify="space-between"
        >
          <FormControl>
            <VStack>
            <Text fontFamily={"Arial"} fontWeight={900} pl={4} pr={4}>Toernooi enkelspel.</Text>
            <Text fontFamily={"Arial"} fontWeight={900} pl={4} pr={4}>24 augustus 2024, 16u00. The Corner, Ninove.</Text>
            {/* <Text fontFamily={"Arial"} fontWeight={900} pl={4} pr={4}>Door de grote vraag werden 5 extra plaatsen voorzien</Text> */}
            <Text fontFamily={"Arial"} fontWeight={900} pl={4} pr={4}>Max aantal spelers: {maxPlayers}. Aantal vrije plaatsen: {maxPlayers - numberOfPlayers}.</Text>
              {error && (
                <ErrorMessage message={error} onClose={() => setError(null)} />
              )}
              
               {isGridVisible ? (
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <>
              <Input
                bgColor="green"
                h={{ base: "50px", sm: "30px", md: "50px" }}
                w="200px"
                border="4px"
                borderColor="blackAlpha.900"
                placeholder="Voornaam"
                _placeholder={{ color: "blackAlpha.900" }}
                onChange={(e) => setFirstname(e.currentTarget.value)}
                isRequired
              ></Input>
              <Input
                bgColor="green"
                h={{ base: "50px", sm: "30px", md: "50px" }}
                w="200px"
                border="4px"
                borderColor="blackAlpha.900"
                placeholder="Familienaam"
                _placeholder={{ color: "blackAlpha.900" }}
                onChange={(e) => setLastname(e.currentTarget.value)}
                isRequired
              ></Input>
              <Input
                bgColor="green"
                h={{ base: "50px", sm: "30px", md: "50px" }}
                w="200px"
                border="4px"
                borderColor="blackAlpha.900"
                placeholder="E-mailadres"
                _placeholder={{ color: "blackAlpha.900" }}
                onChange={(e) => setEmail(e.currentTarget.value)}
                isRequired
              ></Input>
              <Input
                bgColor="green"
                h={{ base: "50px", sm: "30px", md: "50px" }}
                w="200px"
                border="4px"
                borderColor="blackAlpha.900"
                placeholder="Telefoonnummer"
                _placeholder={{ color: "blackAlpha.900" }}
                onChange={(e) => setPhone(e.currentTarget.value)}
                isRequired
              ></Input>
              </>
              </Grid>
              ) : (
                <VStack>
                    <>
              <Input
                bgColor="green"
                h={{ base: "50px", sm: "30px", md: "50px" }}
                w="200px"
                border="4px"
                borderColor="blackAlpha.900"
                placeholder="Voornaam"
                _placeholder={{ color: "blackAlpha.900" }}
                onChange={(e) => setFirstname(e.currentTarget.value)}
                isRequired
              ></Input>
              <Input
                bgColor="green"
                h={{ base: "50px", sm: "30px", md: "50px" }}
                w="200px"
                border="4px"
                borderColor="blackAlpha.900"
                placeholder="Familienaam"
                _placeholder={{ color: "blackAlpha.900" }}
                onChange={(e) => setLastname(e.currentTarget.value)}
                isRequired
              ></Input>
              <Input
                bgColor="green"
                h={{ base: "50px", sm: "30px", md: "50px" }}
                w="200px"
                border="4px"
                borderColor="blackAlpha.900"
                placeholder="E-mailadres"
                _placeholder={{ color: "blackAlpha.900" }}
                onChange={(e) => setEmail(e.currentTarget.value)}
                isRequired
              ></Input>
              <Input
                bgColor="green"
                h={{ base: "50px", sm: "30px", md: "50px" }}
                w="200px"
                border="4px"
                borderColor="blackAlpha.900"
                placeholder="Telefoonnummer"
                _placeholder={{ color: "blackAlpha.900" }}
                onChange={(e) => setPhone(e.currentTarget.value)}
                isRequired
              ></Input>
              </>
                </VStack>
              )}
              
            </VStack>
          </FormControl>
        </Flex>
      </form>
      <Flex>
        <HStack m="0 auto" spacing="5">
          <Button
            h={{ base: "40px", sm: "20px", md: "40px" }}
            type="submit"
            form="Register"
          >
            Registreren
          </Button>
        </HStack>
      </Flex>
      </>)}
    </>
  );
}
