import http from "./http";
import Season from "../type/Season";
import Player from "../type/Player";

const UrlAddition: string = `/season`;

export async function getSeasons(competition_id: number) {
  try {
    const response = await http.get<Season[]>(`${UrlAddition}/competition`, {
      params: { competition_id },
    });
    console.log("Seasons data in service/season.tsx:", response.data);
    if (response.data){
      return response.data
    }
    else{
      return []
    }

  } catch (error: any) {
    console.error(error);
    return error.message;
  }
}

export async function getPlayerStatsFromSeason(season_id: number) {
  try {
    const response = await http.get<Player[]>(`${UrlAddition}/players`, {
      params: { season_id },
    });
    console.log("Player stats in service/season.tsx:", response.data);
    if (response.data){
      return response.data
    }
    else{
      return [];
    }

  } catch (error: any) {
    console.error(error);
    return error.message;
  }
}
