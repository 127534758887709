import React, { useCallback, useState, useEffect } from "react";
import { NavigateFunction, useNavigate, useParams, useLocation, Location  } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Box, Heading, Spinner } from "@chakra-ui/react";
import User from "../../type/User";
import Player from "../../type/Player";
import { playerRegister } from "../../service/player";
import ErrorMessageComponent from "../parts/ErrorMessage";
import FontSize from "../../stylingObjects/FontSize";
import { PlayerStats } from "../parts/PlayerStats";

interface AddEditPlayerLocationState {
  season_id: number;
}

const AddEditPlayer: React.FC = () => {
  const [successful, setSuccessful] = useState<boolean>(false);
  const [user, setUser] = useState<User | any>({});
  const [error, setError] = useState<Error | null | any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAdminMode, setIsAdminMode] = useState<boolean>(false);

  let navigate: NavigateFunction = useNavigate();

  let isAddMode = true; // blijft nu default op true staan, zal later aangepast worden als we de edit functionaliteit uitwerken

  const location = useLocation() as Location & { state: AddEditPlayerLocationState };
  const season_id = location.state?.season_id ?? -1;

  let SeasonId = -1;

  if (!isNaN(season_id)) {
    SeasonId = season_id;
    console.log("id is nu: ", SeasonId);
    console.log("typeof id is nu: ", typeof SeasonId);
  }

  const initialValues: Player = {
    name: "",
  };

  const requiredFieldMessage = "Dit veld is verplicht";
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .test(
        "len",
        "De spelernaam moet tussen 3 en 255 tekens lang zijn",
        (val: any) =>
          val && val.toString().length >= 3 && val.toString().length <= 255
      )
      .required(requiredFieldMessage),
  });

  const handleRegister = async (formValue: Player) => {
    console.log("formValue is: ", formValue);
    console.log("formValue.name of the player is: ", formValue.name);
    const { name } = formValue;
    console.log(
      "Attempting to register player with name:",
      name,
      "and SeasonId:",
      SeasonId
    );

    setError(null);
    await playerRegister(name, SeasonId).then(
      (response) => {
        console.log("response is: ", response);
        setSuccessful(true);
        window.location.reload();
        //navigate(`/competities`);
      },
      (error) => {
        console.log("error is: ", error);
        setError(error);
        setSuccessful(false);
      }
    );
  };

  const handleUpdate = async (formValue: Player) => {
    console.log("formValue is: ", formValue);
    // TODO deze nog uitwerken
  };

  const handleSubmit = (formValue: Player) => {
    isAddMode ? handleRegister(formValue) : handleUpdate(formValue); // later uitbreiden met de edit functionaliteit
  };

  return (
    <>
      <Heading as="h2" size="lg" mb={5} color="green">
        {isAddMode
          ? `Nieuwe speler registreren voor seizoen met id ${SeasonId}`
          : "speler bewerken"}
      </Heading>
      {loading && <Spinner color="red" />}
      {error && !loading && <ErrorMessageComponent message={error.message} onClose={() => setError(null)}/>}
      {!error && !loading && (
        <Box bg="lightgrey" p={4} rounded="md">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              {!successful && (
                <div>
                  <div className="form-group">
                    <label htmlFor="firstName" > Speler naam </label>
                    <Field name="name" type="text" className="form-control" />
                    <ErrorMessage
                      name="SpelerNaam"
                      component="div"
                      className="alert alert-danger"
                    />
                  </div>

                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-primary btn-block"
                      // set the color of the button to green and add a margin on all sides and text in white
                      style={{
                        backgroundColor: "green",
                        margin: "10px 0px 10px 0px",
                        padding: "10px",
                        color: "white",
                      }}
                    >
                      {isAddMode ? "Toevoegen" : "Bijwerken"}
                    </button>
                  </div>
                </div>
              )}
            </Form>
          </Formik>
        </Box>
      )}
      <PlayerStats seasonId={SeasonId} />
    </>
  );
};

export default AddEditPlayer;
