import { useState, useEffect, useCallback } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Spinner,
  Center,
  VStack,
  IconButton,
} from "@chakra-ui/react";
import { getMatchesFromPlayingday } from "../../service/match";
import Match from "../../type/Match";
import FontSize from "../../stylingObjects/FontSize";
import { BsPencil, BsFillTrashFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

interface Props {
  playingday_id: number | null;
  season_id: number | null;
}

const Matches: React.FC<Props> = ({ playingday_id, season_id }) => {
  const [matches, setMatches] = useState<Match[]>([]);
  const [loading, setLoading] = useState(false);
  const [isAdminMode, setIsAdminMode] = useState<boolean>(false);

  const navigate = useNavigate();

  const fetchMatches = useCallback(async () => {
    try {
      setLoading(true);
      if (playingday_id) {
        const fetchedMatches = await getMatchesFromPlayingday(playingday_id);
        setMatches(fetchedMatches);
      } else {
        setMatches([]);
      }
    } catch (error: any) {
      console.error("Error fetching matches:", error.message);
    } finally {
      setLoading(false);
    }
  }, [playingday_id]);

  // check if user is admin
  const checkUserAdminRole = useCallback(() => {
    // check if user is admin: get localstorage user, check if 'Roles' contains 'ADMIN'
    const user = JSON.parse(localStorage.getItem("User") || "null");
    if (user) {
      console.log("user roles:", user.roles);
      const validRoles = ["ADMIN", "COMPETITIONADMIN", "SEASONADMIN"];
      if (user.roles.some((role: string) => validRoles.includes(role))) {
        setIsAdminMode(true);
        console.log("user is an admin");
      } else {
        setIsAdminMode(false);
        console.log("user is not an admin");
      }
    } else {
      console.log("no user found");
      console.log("isAdminMode:", isAdminMode);
    }
  }, []);

  useEffect(() => {
    fetchMatches();
    // check if user is admin
    checkUserAdminRole();
  }, [checkUserAdminRole, fetchMatches]);

  const handleEditMatch = (match_id: number | any) => {
    navigate("/match/addedit", {
      state: {
        match_id,
        isAdminMode,
        isAddMode: false,
        playingday_id,
        season_id,
      },
    });
  };

  return (
    <>
      <VStack>
        {loading && <Spinner color="red" />}
        {!loading && matches.length === 0 && (
          <Text color="red">Geen matchen gevonden voor deze speeldag</Text>
        )}
        {!loading && matches.length > 0 && (
          <Table color="green" fontSize={{ ...FontSize.header }} size="sm">
            <Thead>
              <Tr>
                <Th>
                  <Center>
                    <Text color="green" fontSize={{ ...FontSize.header }}>
                      Speler 1
                    </Text>
                  </Center>
                </Th>
                <Th>
                  <Center>
                    <Text color="green" fontSize={{ ...FontSize.header }}>
                      Score 1
                    </Text>
                  </Center>
                </Th>
                <Th>
                  <Center>
                    <Text color="green" fontSize={{ ...FontSize.header }}>
                      Score 2
                    </Text>
                  </Center>
                </Th>
                <Th>
                  <Center>
                    <Text color="green" fontSize={{ ...FontSize.header }}>
                      Speler 2
                    </Text>
                  </Center>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {matches.map((match) => (
                <Tr key={match.match_id}>
                  <Td>
                    <Center>
                      <Text fontSize={{ ...FontSize.header }}>
                        {match.player1.name}
                      </Text>
                    </Center>
                  </Td>
                  <Td>
                    <Center>
                      <Text fontSize={{ ...FontSize.header }}>
                        {match.player1FramesWon}
                      </Text>
                    </Center>
                  </Td>
                  <Td>
                    <Center>
                      <Text fontSize={{ ...FontSize.header }}>
                        {match.player2FramesWon}
                      </Text>
                    </Center>
                  </Td>
                  <Td>
                    <Center>
                      <Text fontSize={{ ...FontSize.header }}>
                        {match.player2.name}
                      </Text>
                    </Center>
                  </Td>
                  {isAdminMode && (
                    <>
                    <Td>
                      <Center>
                        <Text fontSize={{ ...FontSize.header }}>
                          {match.match_id}
                        </Text>
                      </Center>
                    </Td>
                    <Td>
                      <Center>
                        <IconButton
                          aria-label="edit"
                          variant="outline"
                          colorScheme="green"
                          fontSize="20px"
                          icon={<BsPencil />}
                          _hover={{ color: "white", bg: "green" }}
                          onClick={() => {
                            console.log("Edit match:", match.match_id);
                            handleEditMatch(match.match_id);
                          }}
                        />
                        {/* <IconButton
                          aria-label="delete"
                          variant="outline"
                          colorScheme="red"
                          fontSize="20px"
                          icon={<BsFillTrashFill />}
                          _hover={{ color: "white", bg: "red.500" }}
                          onClick={() => {
                            if (window.confirm("Are you sure you want to delete this match?")) {
                              console.log("Delete match:", match.match_id);
                              // Handle delete match action here
                            }
                          }}
                        /> */}
                      </Center>
                    </Td>
                    </>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </VStack>
    </>
  );
};

export default Matches;
