import {
  Center,
  Flex,
  Grid,
  GridItem,
  Text
} from "@chakra-ui/react";
import { LoginForm } from "../parts/LoginForm";
import Fontsize from "../../stylingObjects/FontSize"
import biljartTafelLeeg from "../../assets/images/biljarttafelLeeg.png";
import biljartTafelLeegVert from "../../assets/images/biljarttafelLeegVerticaal.png";

export default function Aanmelden() {
  return (
    <>
      <Center>
        <Flex
          bgImage={{ base: biljartTafelLeegVert, sm: biljartTafelLeeg }}
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          mt="40px"
          width={{
            base: "326px",
            sm: "422px",
            md: "634px",
            lg: "845px",
            xl: "1056px",
          }}
          h={{
            base: "634px",
            sm: "217px",
            md: "326px",
            lg: "435px",
            xl: "544px",
          }}
        >
          <Flex h="90%" w="95%" m="0 auto" alignSelf="center">
            <Grid
              templateAreas={` 
                    "blank1"
                    "form"
                    "blank2"
                  `}
              templateRows="1fr 3fr 1fr"
              w="100%"
            >
              <GridItem></GridItem>

              <GridItem gridArea="form" height="100%" width="100%" display={localStorage.getItem("User")? "none" : "block"}>
                <LoginForm />
              </GridItem>

              <GridItem gridArea="form" height="100%" width="100%" display={localStorage.getItem("User")? "block" : "none"}><Center><Text fontSize={{...Fontsize.footer}}>Already logged in hombre</Text></Center></GridItem>
            </Grid>
          </Flex>
        </Flex>
      </Center>
    </>
  );
}
