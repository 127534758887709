import { Center, Text, Box, VStack, Flex } from "@chakra-ui/react";
import Fontsize from "../../stylingObjects/FontSize";
import seizoen_1_winners from "../../assets/images/winnaars/seizoen_1_winners.jpg";
import seizoen_2_winners from "../../assets/images/winnaars/seizoen_2_winners.jpg";

export default function HallOfFame() {
  return (
    <>
      <Center flexDir="column">
        <Text fontSize={{ ...Fontsize.subTitle }} color="green" mt="40px">
          WINNAARS
        </Text>
        <Flex flexWrap="wrap" justify="flex-start" gap={1}>
          <Box minWidth="30%" width="500px">
            <Text
              fontSize={{ ...Fontsize.subTitle }}
              color="green"
              mt="20px"
              align="center"
            >
              Seizoen 1
            </Text>
            <Box>
              <Center>
                <img
                  src={seizoen_1_winners}
                  alt="Seizoen 1 winnaars"
                  style={{
                    maxWidth: "50%",
                  }}
                />
              </Center>
            </Box>
          </Box>
          <Box minWidth="30%" width="500px">
            <Text
              fontSize={{ ...Fontsize.subTitle }}
              color="green"
              mt="20px"
              align="center"
            >
              Seizoen 2
            </Text>
            <Box>
              <Center>
                <img
                  src={seizoen_2_winners}
                  alt="Seizoen 2 winnaars"
                  style={{
                    maxWidth: "50%",
                  }}
                />
              </Center>
            </Box>
          </Box>
        </Flex>
      </Center>
    </>
  );
}
