import { Center, Heading, Text } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import fontsize from "../../stylingObjects/FontSize"

export default function NotFound() {
  const { pathname } = useLocation();
  return (
    <>
      <Center><Heading as={"h2"} fontSize={{...fontsize.title}} color="green" mt={5} fontFamily="BitmapFont" textAlign="center">
        Oeps, deze pagina bestaat niet 
      </Heading>
      </Center>
      <Text fontSize={{...fontsize.footer}} color="green" mt="2" p="10px">
        Er is geen pagina gevonden op deze locatie: "{pathname}". Probeer het opnieuw of ga
        terug naar de homepagina.
      </Text>
    </>
  );
}
