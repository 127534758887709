import "./App.css";
import ScrollToTop from "./components/parts/ScrollToTop";
import { Grid, GridItem } from "@chakra-ui/react";
import { Routes, Route } from "react-router";
import Menu from "./components/parts/Menu";
import Home from "./components/pages/Home";
import Footer from "./components/parts/Footer";
import WIP from "./components/pages/WIP";
import NotFound from "./components/pages/NotFound";
import Aanmelden from "./components/pages/Aanmelden";
import Competities from "./components/pages/Competities";
import HallOfFame from "./components/pages/HallOfFame";
import AddEditPlayer from "./components/adminpages/AddEditPlayer";
import AddEditPlayingday from "./components/adminpages/AddEditPlayingday";
import AddEditMatch from "./components/adminpages/AddEditMatch";
import TournamentRegistration from "./components/pages/TournamentRegistration";
import { SuccessPage } from "./components/pages/SuccesRegistration";

function App() {
  return (
    <>
      <ScrollToTop />
      <Grid
        templateAreas={` 
          "menu"
          "content"
          "footer"
        `}
        height="100vh"
        // templateColumns="1fr"
        templateRows="auto 1fr auto"
      >
        <GridItem gridArea="menu" bgColor="blackAlpha.900">
          {<Menu />}
        </GridItem>
        <GridItem
          gridArea="content"
          height="100%"
          width="100%"
          bgColor="blackAlpha.900"
        >
          <Routes>
            <Route index element={<Home />} />
            <Route path="/aanmelden" element={<Aanmelden />} />
            <Route path="/competities" element={<Competities />} />
            <Route path="/halloffame" element={<HallOfFame />} />
            <Route path="/player/addedit" element={<AddEditPlayer />} />
            <Route path="/playingday/addedit" element={<AddEditPlayingday />} />
            <Route path="/match/addedit" element={<AddEditMatch />} />
            <Route path="/tournament" element={<TournamentRegistration />} />
            <Route path="/success" element={<SuccessPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </GridItem>
        <GridItem gridArea="footer" bgColor="blackAlpha.800">
          {<Footer />}
        </GridItem>
      </Grid>
    </>
  );
}

export default App;
