import Competition from "../type/Competition";
import http from "./http";

const UrlAddition: string = `/competition`;

export async function getCompetitions() {
  try {
    const competitions = await http.get<Competition[]>(`${UrlAddition}/all`);
    if(competitions.data){
      return competitions.data
    }
    else {
      throw Error("No competitions were found or backservice offline")
    }
  } catch (error: any) {
    console.error(error);
    throw Error(error.message);
  }
}