import { useState, useEffect } from "react";
import * as healthApi from "../../service/health";
import { Box, Flex, Icon, Text, Spinner } from "@chakra-ui/react";
import {
  BsFillExclamationOctagonFill,
  BsFillHandThumbsUpFill,
} from "react-icons/bs";

export default function Health() {
  const [health, setHealth] = useState<string | null>(null);
  const [error, setError] = useState<Error | null | any>(null);
  const [loading, setLoading] = useState(false);
  const [isWebserviceStarted, setIsWebserviceStarted] = useState(true);

  setTimeout(() => setIsWebserviceStarted(false), 3000); // 3 seconds wachten, om specifieke melding te kunnen geven als webserver nog niet opgestart is.

  useEffect(() => {
    const fetchPing = async () => {
      try {
        setLoading(true);
        setError(null);
        const data = await healthApi.getPing();
        setHealth(data);
        console.log("ping", data);
      } catch (error) {
        console.error(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchPing();
  }, []);

  return (
    <>
      <Flex flexWrap="wrap" justifyContent="left" m="5">
        {loading && !isWebserviceStarted && (
          <Box>
            <Text fontSize="sm">even geduld</Text>
          </Box>
        )}

         {/*
        {!loading && !error && { health }  ? (
          <>
            {/* <Icon
              as={BsFillHandThumbsUpFill}
              w={8}
              h={8}
              color="green.100"
            /> 
          </>
        ) : (
          <>
            <Icon
              as={BsFillExclamationOctagonFill}
              w={8}
              h={8}
              color="red.500"
            />
          </>
        )}
        */}
      </Flex>
    </>
  );
}
