//The code below checks Local Storage for user item. 
//If there is a logged in user with accessToken (JWT), return HTTP Authorization header. 
//Otherwise, return an empty object.

export default function authHeader() {
  const tokenStr = localStorage.getItem("token");

  console.log("token", tokenStr);

  if (tokenStr) {
    return { Authorization: "Bearer " + tokenStr };
  } else {
    return { Authorization: "" };
  }
}
