import {
  Box,
  Alert,
  AlertIcon,
  AlertDescription,
  CloseButton,
} from "@chakra-ui/react";

export default function ErrorMessage({
  message,
  onClose,
}: {
  message: string;
  onClose: () => void;
}) {
  return (
    <Box my={4}>
      <Alert status="error" borderRadius={4}>
        <AlertIcon />
        <AlertDescription fontSize={{ base: "11", md: "20" }}>
          {message}
        </AlertDescription>
        <CloseButton position="absolute" right="-8px" top="-8px" onClick={onClose} />
      </Alert>
    </Box>
  );
}