import http from "./http";

export async function userLogin(username: string, password: string) {
  try {
    return http
    .post(`/auth/login`, null, {
      headers: { Authorization: "application/JSON" },
      params: { username, password },
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("User", JSON.stringify(response.data.user));
      } else throw Error(response.data);
    });
    
  } catch (error: any) {
     return(error.message)
  }
  

}