import { Box, Center, Text } from "@chakra-ui/react";
import fontsize from "../../stylingObjects/FontSize"




export default function Footer(){
    return(
    <>
    <Box><Center><Text fontSize={{...fontsize.footer}} fontFamily="BitmapFont" color="darkgreen">By Valckenier Dimitri - Dauchot Joachim</Text></Center></Box>
    </>
    )
}