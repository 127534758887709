import http from "./http";

const UrlAddition: string = `/health`;

export const getPing = async () => {
  const { data } = await http.get<any>(`${UrlAddition}/ping`);
  return data.pong;
};

export const getVersion = async () => {
  const { data } = await http.get<any>(`${UrlAddition}/version`);
  return data.pong;
};
