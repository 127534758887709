import { Center, Box,Text, VStack, Button} from "@chakra-ui/react";
import User from "../../type/User"
import Fontsize from "../../stylingObjects/FontSize"
import biljartTafelVer from "../../assets/images/biljarttafeVerticaall.png"
import biljartTafelHor from "../../assets/images/biljarttafel.png"



export default function Home() {
  const user: User | null = localStorage.getItem("User")? JSON.parse(localStorage.getItem("User")!) : null;
  const name: string | undefined = user?.username;


  return (
    
    <>
    <Center>
      <VStack>
      {/* <button
        type="submit"
        className="btn btn-primary btn-block"
        style={{
          backgroundColor: "green",
          margin: "10px 0px 10px 0px",
          padding: "10px",
          color: "white",
        }}
      >
        <a href="/tournament">Inschrijvingen toernooi</a>
      </button> */}
      <Box bgImage={{base:biljartTafelVer, sm:biljartTafelHor}} backgroundSize="contain" backgroundRepeat="no-repeat" mt="40px"  width={{base:"326px",sm:"422px",md:"634px", lg:"845px",xl:"1056px"}} h={{base:"634px",sm:"217px",md:"326px", lg:"435px",xl:"544px"}}>
        <Box mt={{base:"3rem", sm:"2rem", md:"3rem", xl:"4rem"}}>
          <Center>
          <Text fontSize={{...Fontsize.footer}}>
             {name? "Welkom " + name : ""}

          </Text>
          {/* <VStack>
          <Text fontFamily={"Arial"} fontWeight={900} pt={2} fontSize={30}>15 juli 2023, 13u00</Text>
          <Text fontFamily={"Arial"} fontWeight={900} pt={5} fontSize={30}>The Corner, Ninove</Text>
          </VStack> */}
          </Center>
        </Box>

      </Box>
      </VStack>
    </Center>
    </>
  );
}