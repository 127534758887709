import { extendTheme } from "@chakra-ui/react";

const colors = {
  brand: {
    900: "#1a365d",
    800: "#153e75",
    700: "#2a69ac",
  },
  blackButton: {
    500: "rgba(0, 0, 0, 0.8)",
    600: "rgba(0, 0, 0, 0.6)",
    700: "rgba(0, 0, 0, 1)",
  },
  greenButton: {
    500: "rgba(0, 200, 0, 0.8)",
    600: "rgba(0, 200, 0, 0.6)",
    700: "rgba(0, 200, 0, 1)",
  },
};

const components = {
  Button: {
    baseStyle: {
      fontFamily: "BitmapFont",
    },
    variants: {
      solid: {
        color: "green",
      },
      greenButton: {
        color: "black",
        bg: "greenButton.500",
        _hover: {
          bg: "greenButton.600",
        },
      },
    },
    defaultProps: {
      colorScheme: "blackButton",
    },
  },
};

const theme = extendTheme({ colors, components });

export default theme;
