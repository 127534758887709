import {
  Button,
  Flex,
  FormControl,
  Input,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { userLogin } from "../../service/auth";
import { useNavigate } from "react-router";
import ErrorMessage from "./ErrorMessage";
import { RegistreerButton } from "./RegistreerButton";
import { userRegister } from "../../service/user";

export function LoginForm() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>("");

  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    try {
      await userLogin(username, password);
      navigate("/");
    } catch (error: any) {
      setError("wrong password/username combination");
    }
  }

  return (
    <>
      <form id="Login" onSubmit={handleSubmit} style={{ height: "100%" }}>
        <Flex
          h="100%"
          align={{ base: "start", sm: "center" }}
          justify="space-between"
          display={localStorage.getItem("User") ? "none" : "flex"}
        >
          <FormControl>
            <VStack>
              {error && (
                <ErrorMessage message={error} onClose={() => setError(null)} />
              )}
              <Input
                bgColor="green"
                h={{ base: "50px", sm: "30px", md: "50px" }}
                w="200px"
                border="4px"
                borderColor="blackAlpha.900"
                placeholder="Gebruikersnaam"
                _placeholder={{ color: "blackAlpha.900" }}
                onChange={(e) => setUsername(e.currentTarget.value)}
                isRequired
              ></Input>
              <Input
                bgColor="green"
                h={{ base: "50px", sm: "30px", md: "50px" }}
                w="200px"
                border="4px"
                borderColor="blackAlpha.900"
                placeholder="Paswoord"
                _placeholder={{ color: "blackAlpha.900" }}
                type="password"
                onChange={(e) => setPassword(e.currentTarget.value)}
                isRequired
              ></Input>
            </VStack>
          </FormControl>
        </Flex>
      </form>
      <Flex>
        <HStack m="0 auto" spacing="5">
          <Button
            h={{ base: "40px", sm: "20px", md: "40px" }}
            type="submit"
            form="Login"
          >
            Aanmelden
          </Button>
          {/* <RegistreerButton /> */}
        </HStack>
      </Flex>
    </>
  );
}
