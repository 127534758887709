import http from "./http";
import Playingday from "../type/Playingday";

const UrlAddition: string = `/playingday`;

export async function getPlayingdaysFromSeason(season_id: number) {
  try {
    const playingdays = await http.get<Playingday[]>(`${UrlAddition}/season`, {
      params: { season_id },
    });
    console.log(
      "Playingdays by season_id in service/playingday.tsx:",
      playingdays.data
    );
    if (playingdays.data) {
      return playingdays.data;
    } else {
      return [];
    }
  } catch (error: any) {
    console.error(error);
    return error.message;
  }
}

export async function getPlayingdayById(playingday_id: number) {
  try {
    const playingday = await http.get<Playingday>(`${UrlAddition}/`, {
      params: { playingday_id },
    });
    console.log(
      "Playingdays by playingday_id in service/playingday.tsx:",
      playingday.data
    );
    if (playingday.data) {
      return playingday.data;
    } else {
      return [];
    }
  } catch (error: any) {
    console.error(error);
    return error.message;
  }
}

export async function playingdayRegister(
  date: Date,
  season_id: number,
  verslag?: String
) {
  try {
    const formattedDate = date.toISOString().slice(0, 10);
    const token = localStorage.getItem("token");

    if (!token) {
      throw new Error("No JWT token found in localStorage");
    }

    const response = await http.post<Playingday | any>(
      `${UrlAddition}/create`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          date: formattedDate,
          verslag: verslag,
          season_id: season_id,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error(error);
    throw Error(error.message);
  }
}

export async function playingdayUpdate(
  date: Date,
  playingday_id: number,
  verslag?: String,
  is_finished?: boolean
) {
  try {
    const formattedDate = date.toISOString().slice(0, 10);
    const token = localStorage.getItem("token");

    if (!token) {
      throw new Error("No JWT token found in localStorage");
    }

    const response = await http.put<Playingday | any>(
      `${UrlAddition}/update`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          date: formattedDate,
          verslag: verslag,
          playingday_id: playingday_id,
          is_finished: is_finished,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error(error);
    throw Error(error.message);
  }
}

// export async function playingdayRegister(date: Date, seasonId: number) {
//   try {
//     const formattedDate = date.toISOString().slice(0, 10);
//     const token = localStorage.getItem("token"); // Get the JWT token from your authentication process
//     const response = await http.post<Playingday | any>(
//       `${UrlAddition}/create`,
//       null,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "application/json",
//         },
//         params: { date: formattedDate, season_id: seasonId },
//       }
//     );
//     return response.data;
//   } catch (error: any) {
//     console.error(error);
//     throw Error(error.message);
//   }
// }
