import React, { useCallback, useState, useEffect, useMemo } from "react";
import {
  NavigateFunction,
  useNavigate,
  useLocation,
  Location,
} from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Box, Heading, Spinner } from "@chakra-ui/react";
import User from "../../type/User";
import {
  playingdayRegister,
  getPlayingdayById,
  playingdayUpdate,
} from "../../service/playingday";
import ErrorMessageComponent from "../parts/ErrorMessage";
import Playingday from "../../type/Playingday";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Playingdays } from "../parts/Playingdays";

interface AddEditPlayingdayLocationState {
  season_id: number;
}

const AddEditPlayingday: React.FC = () => {
  const [successful, setSuccessful] = useState<boolean>(false);
  const [user, setUser] = useState<User | any>({});
  const [error, setError] = useState<Error | null | any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAdminMode, setIsAdminMode] = useState<boolean>(false);
  const [fetchedPlayingday, setFetchedPlayingday] = useState<Playingday | null>(
    null
  );
  const [loadingPlayingday, setLoadingPlayingday] = useState<boolean>(true);

  let navigate: NavigateFunction = useNavigate();

  const location = useLocation() as Location & {
    state: AddEditPlayingdayLocationState;
  };
  const season_id = location.state?.season_id ?? -1;
  const isAddMode = location.state?.isAddMode ?? true;
  const playingday_id = location.state?.playingday_id ?? -1;

  let SeasonId = -1;
  if (!isNaN(season_id)) {
    SeasonId = season_id;
    console.log("id is nu: ", SeasonId);
    console.log("typeof id is nu: ", typeof SeasonId);
  }

  const fetchPlayingday = useCallback(async () => {
    if (!isAddMode) {
      setLoadingPlayingday(true);
      try {
        const playingday = await getPlayingdayById(playingday_id);
        setFetchedPlayingday(playingday);
      } catch (error) {
        console.error("Error fetching playingday:", error);
      } finally {
        setLoadingPlayingday(false);
      }
    } else {
      setLoadingPlayingday(false);
    }
  }, [isAddMode, playingday_id]);

  useEffect(() => {
    fetchPlayingday();
  }, [fetchPlayingday]);

  // const initialValues: Playingday = useMemo(
  //   () => ({
  //     date: isAddMode ? new Date() : fetchedPlayingday?.date ?? new Date(),
  //     is_finished: isAddMode ? false : fetchedPlayingday?.is_finished ?? false,
  //     verslag: isAddMode ? "" : fetchedPlayingday?.verslag ?? "",
  //   }),
  //   [isAddMode, fetchedPlayingday]
  // );

  const initialValues: Playingday = useMemo(() => {
    const parsedDate = fetchedPlayingday?.date ? new Date(fetchedPlayingday.date) : new Date();
  
    return {
      date: isAddMode ? new Date() : parsedDate,
      is_finished: isAddMode ? false : fetchedPlayingday?.is_finished ?? false,
      verslag: isAddMode ? "" : fetchedPlayingday?.verslag ?? "",
    };
  }, [isAddMode, fetchedPlayingday]);

  const requiredFieldMessage = "Dit veld is verplicht";
  const validationSchema = Yup.object().shape({
    date: Yup.date().required(requiredFieldMessage),
    is_finished: Yup.boolean(),
    verslag: Yup.string(),
  });

  const handleRegister = async (formValue: Playingday) => {
    const { date, is_finished, verslag } = formValue;
    console.log(
      "Attempting to register playingday with date:",
      date,
      "for season with SeasonId:",
      SeasonId
    );

    if (date) {
      setError(null);
      try {
        const response = await playingdayRegister(date, SeasonId, verslag);
        console.log("response is: ", response);
        setSuccessful(true);
        window.location.reload();
        //navigate(`/competities`);
      } catch (error) {
        console.log("error is: ", error);
        setError(error);
        setSuccessful(false);
      }
    } else {
      setError(new Error("Date is required"));
      setSuccessful(false);
    }
  };

  const handleUpdate = async (formValue: Playingday) => {
    console.log("formValue is: ", formValue);
    const { date, is_finished, verslag } = formValue;
    if (date) {
      setError(null);
      try {
        const response = await playingdayUpdate(date, playingday_id, verslag, is_finished);
        console.log("response is: ", response);
        setSuccessful(true);
        window.location.reload();
        //navigate(`/competities`);
      } catch (error) {
        console.log("error is: ", error);
        setError(error);
        setSuccessful(false);
      }
    } else {
      setError(new Error("Date is required"));
      setSuccessful(false);
    }
  };

  const handleSubmit = (formValue: Playingday) => {
    isAddMode ? handleRegister(formValue) : handleUpdate(formValue); // later uitbreiden met de edit functionaliteit
  };

  return (
    <>
      <Heading as="h2" size="lg" mb={5} color="green">
        {isAddMode
          ? `Nieuwe speeldag registreren voor seizoen met id ${SeasonId}`
          : "speeldag bewerken"}
      </Heading>
      {loading && <Spinner color="red"  />}
      {error && !loading && (
        <ErrorMessageComponent
          message={error.message}
          onClose={() => setError(null)}
        />
      )}
      {!error && !loading && !loadingPlayingday && (
        <Box bg="lightgrey" p={4} rounded="md">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form>
                {!successful && (
                  <div>
                    <div className="form-group">
                      <label htmlFor="date">Date</label>
                      <DatePicker
                        selected={values.date}
                        onChange={(date: any) => setFieldValue("date", date)}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="date"
                        component="div"
                        className="alert alert-danger"
                      />
                    </div>

                    {!isAddMode && (
                      <div className="form-group">
                        <label htmlFor="is_finished">
                          <Field type="checkbox" name="is_finished" />
                          Is Finished
                        </label>
                        <ErrorMessage
                          name="is_finished"
                          component="div"
                          className="alert alert-danger"
                        />
                      </div>
                    )}

                    <div className="form-group">
                      <label htmlFor="verslag">Verslag</label>
                      <Field
                        name="verslag"
                        as="textarea"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        style={{
                          backgroundColor: "green",
                          margin: "10px 0px 10px 0px",
                          padding: "10px",
                          color: "white",
                        }}
                      >
                        {isAddMode ? "Toevoegen" : "Bijwerken"}
                      </button>
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </Box>
      )}
      <Playingdays seasonId={SeasonId} />
    </>
  );
};

export default AddEditPlayingday;
