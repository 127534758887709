var title = {base:'15px',sm:'20px', md:'25px', lg:'35px', xl:'45px'};
var subTitle = {base:'15px',sm:'15px', md:'20px', lg:'30px', xl:'40px'};
var footer = {base:'10px',sm:'15px', md:'15px', lg:'20px', xl:'30px'}
var header = {base:'8px',sm:'8px', md:'10px', lg:'15px', xl:'15px'}

const exportedObject = {
    title,
    subTitle,
    footer, 
    header
}

export default exportedObject




