import {
  Select,
  VStack,
  Text,
  Spinner,
  Flex,
  Button,
  Icon,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { getCompetitions } from "../../service/competition";
import { getSeasons } from "../../service/season";
import Competition from "../../type/Competition";
import Season from "../../type/Season";
import FontSize from "../../stylingObjects/FontSize";
import { Playingdays } from "../parts/Playingdays";
import { PlayerStats } from "../parts/PlayerStats";
import { FaArrowRight } from "react-icons/fa";
import { NavigateFunction, useNavigate } from "react-router-dom";
import User from "../../type/User";

function Competities() {
  let navigate: NavigateFunction = useNavigate();
  const loggedInUser = JSON.parse(localStorage.getItem("User") || "null");

  const [competitions, setCompetitions] = useState<Competition[]>([]);
  const [competitionLoading, setCompetitionLoading] = useState(false);
  const [seasons, setSeasons] = useState<Season[] | null>([]);
  const [seasonsLoading, setSeasonsLoading] = useState(false);
  const [selectedSeason, setSelectedSeason] = useState<Season | null>(null);
  const [showPlayerStats, setShowPlayerStats] = useState<boolean>(true);
  const [competitionSelected, setCompetitionSelected] = useState<number | null>(null);

  const fetchCompetitions = useCallback(async () => {
    try {
      setCompetitionLoading(true);
      const fetchedCompetitions: Competition[] = await getCompetitions();
      setCompetitions(fetchedCompetitions);

      // Automatically select the last competition
      if (fetchedCompetitions.length > 0) {
        const lastCompetition =
          fetchedCompetitions[fetchedCompetitions.length - 1];
        setCompetitionSelected(lastCompetition.competition_id);
        fetchSeasons(lastCompetition.competition_id);
      }
    } catch (error: any) {
      console.error("Error fetching competitions:", error.message);
    } finally {
      setCompetitionLoading(false);
    }
  }, []);

  const fetchSeasons = useCallback(async (competionID: number) => {
    try {
      if (competionID) {
        setSeasonsLoading(true);
        const fetchedSeasons: Season[] = await getSeasons(competionID);
        setSeasons(fetchedSeasons);
        // setSelectedSeason(null);
        // Automatically select the last season
        if (fetchedSeasons.length > 0) {
          const lastSeason = fetchedSeasons[fetchedSeasons.length - 1];
          setSelectedSeason(lastSeason);
        }
      } else {
        setSeasons([]);
        setSelectedSeason(null);
      }
    } catch (error: any) {
      console.error("Error fetching seasons:", error.message);
    } finally {
      setSeasonsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCompetitions();
  }, [fetchCompetitions]);

  const hasValidRole = (user: User | null) => {
    if (user === null) {
      return false;
    }
    const validRoles = ["ADMIN", "COMPETITIONADMIN", "SEASONADMIN"];
    return user.roles.some((role) => validRoles.includes(role));
  };

  return (
    <>
      <VStack>
        <Flex justify="center" mt="20px">
          <VStack>
            {competitionLoading && <Spinner color="red" />}
            <Text color="green" fontSize={{ ...FontSize.header }}>
              Selecteer een competitie
            </Text>
            <Select
              value={competitionSelected || ""}
              // value={competitionSelected ? competitions[competitions.length - 1].competition_id : ""}
              onChange={(e) => {
                const selectedCompetitionId = parseInt(e.currentTarget.value);
                setCompetitionSelected(selectedCompetitionId);
                fetchSeasons(selectedCompetitionId);
                // setCompetitionSelected(true);
                // fetchSeasons(parseInt(e.currentTarget.value));
              }}
              color="green"
              bgColor="blackAlpha.900"
              fontFamily={"BitmapFont"}
              fontSize={{ ...FontSize.header }}
              w="50vw"
              maxW={"450px"}
            >
              <option
                // selected
                disabled
                value=""
                style={{ backgroundColor: "black" }}
              >
                Kies een Competitie
              </option>
              {competitions?.map((competition) => (
                <option
                  key={competition.competition_id}
                  value={competition.competition_id}
                  style={{ backgroundColor: "black" }}
                >
                  {`${competition.competition_name} ${
                    competition.location ? "(" + competition.location + ")" : ""
                  }`}
                </option>
              ))}
            </Select>
            {competitionSelected && (
              <>
                <Text color="green" fontSize={{ ...FontSize.header }}>
                  Selecteer een seizoen
                </Text>
                {seasonsLoading && <Spinner color="red" />}
                {!seasonsLoading && seasons!.length > 0 && (
                  <Select
                    value={selectedSeason ? selectedSeason.season_id : ""}
                    isDisabled={seasonsLoading}
                    onChange={(e) => {
                      setSelectedSeason(
                        seasons?.find(
                          (season) =>
                            season.season_id === parseInt(e.currentTarget.value)
                        ) || null
                      );
                    }}
                    color="green"
                    bgColor="blackAlpha.900"
                    fontFamily={"BitmapFont"}
                    fontSize={{ ...FontSize.header }}
                    w="50vw"
                    maxW={"450px"}
                  >
                    <option
                      selected
                      value=""
                      style={{ backgroundColor: "black" }}
                    >
                      {" "}
                      Kies een Seizoen{" "}
                    </option>
                    {seasons &&
                      seasons.map((season) => (
                        <option
                          key={season.season_id}
                          value={season.season_id}
                          style={{ backgroundColor: "black" }}
                        >
                          {`${season.season_name} ${
                            season.is_finished ? " (afgerond)" : ""
                          } `}
                        </option>
                      ))}
                    {!seasons && <option>Geen seizoenen gevonden</option>}
                  </Select>
                )}
                {!seasonsLoading && seasons!.length === 0 && (
                  <Text color="red">Geen seizoenen gevonden</Text>
                )}
              </>
            )}
            {selectedSeason && (
              <Button
                onClick={() => setShowPlayerStats(!showPlayerStats)}
                variant={"greenButton"}
                rightIcon={<Icon as={FaArrowRight} w={8} h={5} color="black" />}
              >
                {showPlayerStats ? "Toon speeldagen" : "Toon rangschikking"}
              </Button>
            )}
            {selectedSeason &&
              (showPlayerStats ? (
                <PlayerStats seasonId={selectedSeason.season_id} />
              ) : (
                <Playingdays seasonId={selectedSeason.season_id} />
              ))}

            {selectedSeason && hasValidRole(loggedInUser) && (
              <Button
                variant={"greenButton"}
                onClick={() => {
                  if (showPlayerStats) {
                    navigate("/player/addedit", {
                      state: { season_id: selectedSeason.season_id },
                    });
                  } else {
                    navigate("/playingday/addedit", {
                      state: { season_id: selectedSeason.season_id },
                    });
                  }
                }}
              >
                {showPlayerStats ? "Speler toevoegen" : "Speeldag toevoegen"}
              </Button>
            )}
          </VStack>
        </Flex>
      </VStack>
    </>
  );
}

export default Competities;
