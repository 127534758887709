import http from "./http";
import TournamentPlayer from '../type/TournamentPlayer';
import authHeader from "./auth-header";

const UrlAddition: string = `/tournamentplayer`;

export async function tournamentPlayerRegister(firstname: string, lastname: string, email: string, phone: string, tournament_id: number) {
  try {
    const response = await http.post<any>(
      `${UrlAddition}/create`,
      null,
      {
        headers: { Authorization: "application/JSON" },
        params: { firstname, lastname, email, phone, tournament_id },
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    console.error(error.response);
    throw error.response;
  }
}

export async function getTournamentplayersFromTournament(tournament_id: number) {
  try{
    const response = await http.get<TournamentPlayer[]>(`${UrlAddition}/playersoftournament`, {
      headers: authHeader(),
      params: { tournament_id },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw response;
    }
  }
  catch (error: any) {
    console.error(error.response);
    throw error.response;
  }
}
