import React, { useCallback, useState, useEffect, useMemo } from "react";
import {
  NavigateFunction,
  useNavigate,
  useLocation,
  Location,
} from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Box, Heading, Spinner, Text } from "@chakra-ui/react";
import User from "../../type/User";
import ErrorMessageComponent from "../parts/ErrorMessage";
import "react-datepicker/dist/react-datepicker.css";
import Match from "../../type/Match";
import { getMatchById, matchRegister, matchUpdate } from "../../service/match";
import Player from "../../type/Player";
import MatchFormValues from "../../type/MatchFormValues";
import { getPlayersBySeasonId } from "../../service/player";

interface AddEditMatchState {
  playingday_id: number;
  season_id: number;
}

const AddEditMatch: React.FC = () => {
  const [successful, setSuccessful] = useState<boolean>(false);
  const [error, setError] = useState<Error | null | any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchedMatch, setFetchedMatch] = useState<Match | null>(null);
  const [loadingMatch, setLoadingMatch] = useState<boolean>(true);
  const [players, setPlayers] = useState<Player[]>([]);

  let navigate: NavigateFunction = useNavigate();

  const location = useLocation() as Location & {
    state: AddEditMatchState;
  };

  const isAddMode = location.state?.isAddMode ?? true;
  const season_id = location.state?.season_id ?? -1;
  const playingday_id = location.state?.playingday_id ?? -1;
  const match_id = location.state?.match_id ?? -1;
  const isAdminMode = location.state?.isAdminMode ?? false;

  let PlayingdayId = -1;
  if (!isNaN(playingday_id)) {
    PlayingdayId = playingday_id;
    console.log("playingday_id is nu: ", PlayingdayId);
    console.log("typeof id is nu: ", typeof PlayingdayId);
  }

  const fetchPlayers = useCallback(async () => {
    try {
      const fetchedPlayers = await getPlayersBySeasonId(season_id);
      setPlayers(fetchedPlayers);
    } catch (error) {
      console.error("Error fetching players:", error);
    }
  }, [season_id]);

  useEffect(() => {
    fetchPlayers();
  }, [fetchPlayers]);

  const fetchMatch = useCallback(async () => {
    if (!isAddMode) {
      setLoadingMatch(true);
      try {
        console.log("match_id in fetchMatch:", match_id);
        const match = await getMatchById(match_id);
        setFetchedMatch(match);
      } catch (error) {
        console.error("Error fetching match:", error);
      } finally {
        setLoadingMatch(false);
      }
    } else {
      setLoadingMatch(false);
    }
  }, [isAddMode, match_id]);

  useEffect(() => {
    fetchMatch();
  }, [fetchMatch]);

  const initialValues: MatchFormValues = useMemo(() => {
    return {
      player1: isAddMode ? null : fetchedMatch?.player1?.player_id ?? null,
      player2: isAddMode ? null : fetchedMatch?.player2?.player_id ?? null,
      player1FramesWon: isAddMode ? null : fetchedMatch?.player1FramesWon ?? -1,
      player2FramesWon: isAddMode ? null : fetchedMatch?.player2FramesWon ?? -1,
      playingDay: isAddMode ? null : fetchedMatch?.playingDay ?? null,
    };
  }, [isAddMode, fetchedMatch]);

  const requiredFieldMessage = "Dit veld is verplicht";
  const validationSchema = Yup.object().shape({
    player1: Yup.string().nullable().required(requiredFieldMessage),
    player2: Yup.string().nullable().required(requiredFieldMessage),
    player1FramesWon: Yup.number()
      .min(0, "Must be greater than or equal to 0")
      .nullable(),
    player2FramesWon: Yup.number()
      .min(0, "Must be greater than or equal to 0")
      .nullable(),
  });

  const handleRegister = async (formValue: MatchFormValues) => {
    const { player1, player2, player1FramesWon, player2FramesWon } = formValue;

    if (!player1 || !player2) {
      setError("Player1 and Player2 must be selected.");
      return;
    }

    setError(null);
    try {
      const response = await matchRegister(
        playingday_id,
        player1,
        player2,
        player1FramesWon ?? undefined,
        player2FramesWon ?? undefined
      );
      console.log("response is: ", response);
      setSuccessful(true);
      window.location.reload();
      //navigate(`/competities`);
    } catch (error) {
      console.log("error is: ", error);
      setError(error);
      setSuccessful(false);
    }
  };

  const handleUpdate = async (formValue: MatchFormValues) => {
    console.log("formValue is: ", formValue);
    const { player1, player2, player1FramesWon, player2FramesWon } = formValue;

    if (!player1 || !player2) {
      setError("Player1 and Player2 must be selected.");
      return;
    }
    setError(null);
    try {
      const response = await matchUpdate(
        match_id,
        player1,
        player2,
        player1FramesWon ?? undefined,
        player2FramesWon ?? undefined,
        playingday_id,
        
      );
      console.log("response is: ", response);
      setSuccessful(true);
      //window.location.reload();
      navigate(`/competities`);
    } catch (error) {
      console.log("error is: ", error);
      setError(error);
      setSuccessful(false);
    }
  };

  const handleSubmit = (formValue: MatchFormValues) => {
    if (formValue.player1 && formValue.player2) {
      isAddMode ? handleRegister(formValue) : handleUpdate(formValue); 
    } else {
      console.error("Player1 and Player2 must be selected.");
    }
  };

  return (
    <>
      {/* Render the list of players here */}
      {players.map((player) => (
        <div key={player.player_id}>
          <Text color="white"> {player.name} </Text>
        </div>
      ))}
      <Heading as="h2" size="lg" mb={5} color="green">
        {isAddMode
          ? `Nieuwe match registreren voor speeldag met id ${PlayingdayId}`
          : "Match bewerken"}
      </Heading>
      <p style={{ color: "white" }}>isAddMode = {isAddMode.toString()}</p>
      <p style={{ color: "white" }}>isAdminMode = {isAdminMode.toString()}</p>
      <p style={{ color: "white" }}>match id = {match_id}</p>
      {loading && <Spinner color="red" />}
      {error && !loading && (
        <ErrorMessageComponent
          message={error.message}
          onClose={() => setError(null)}
        />
      )}
      {!error && !loading && !loadingMatch && (
        <Box bg="lightgrey" p={4} rounded="md">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form>
                {!successful && (
                  <div>
                    {/* Player1 dropdown */}
                    <div className="form-group">
                      <label htmlFor="player1">Player 1</label>
                      <Field
                        as="select"
                        name="player1"
                        className="form-control"
                      >
                        <option value="">Select Player 1</option>
                        {players.map((player, index) => (
                          <option
                            key={player.player_id}
                            value={player.player_id}
                          >
                            {player.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="player1"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>

                    {/* Player2 dropdown */}
                    <div className="form-group">
                      <label htmlFor="player2">Player 2</label>
                      <Field
                        as="select"
                        name="player2"
                        className="form-control"
                      >
                        <option value="">Select Player 2</option>
                        {players.map((player, index) => (
                          <option
                            key={player.player_id}
                            value={player.player_id}
                          >
                            {player.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="player2"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>

                    {!isAddMode && (
                      <>
                        {/* Player1 Frames Won input*/}
                        <div className="form-group">
                          <label htmlFor="player1FramesWon">
                            Player 1 Frames Won
                          </label>
                          <Field
                            type="number"
                            name="player1FramesWon"
                            className="form-control"
                            min="0"
                          />
                          <ErrorMessage
                            name="player1FramesWon"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        {/* Player2 Frames Won input */}
                        <div className="form-group">
                          <label htmlFor="player2FramesWon">
                            Player 2 Frames Won
                          </label>
                          <Field
                            type="number"
                            name="player2FramesWon"
                            className="form-control"
                            min="0"
                          />
                          <ErrorMessage
                            name="player2FramesWon"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </>
                    )}

                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        style={{
                          backgroundColor: "green",
                          margin: "10px 0px 10px 0px",
                          padding: "10px",
                          color: "white",
                        }}
                      >
                        {isAddMode ? "Toevoegen" : "Bijwerken"}
                      </button>
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </Box>
      )}
      {/* <Playingdays seasonId={SeasonId} /> */}
    </>
  );
};

export default AddEditMatch;
