import Player from "../type/Player";
import http from "./http";

const UrlAddition: string = `/player`;

export async function playerRegister(name: string, seasonId: number) {
  try {
    const response = await http.post<Player | any>(
      `${UrlAddition}/create`,
      null,
      {
        headers: { Authorization: "application/JSON" },
        params: { name, season_id: seasonId },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error(error);
    throw Error(error.message);
  }
}


// get all players from a season
export async function getPlayersBySeasonId(seasonId: number) {
  try {
    const players = await http.get<Player[]>(`${UrlAddition}/season`, {
      params: { season_id: seasonId },
    });
    console.log("Players by season_id in service/player.tsx:", players.data);
    if (players.data) {
      return players.data;
    } else {
      return [];
    }
  } catch (error: any) {
    console.error(error);
    return error.message;
  }
}