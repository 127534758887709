import http from "./http";
import Match from "../type/Match";
import Player from "../type/Player";
import MatchFormValues from '../type/MatchFormValues';

const UrlAddition: string = `/match`;

export async function getMatchesFromPlayingday(playingday_id: number) {
  try {
    const matches = await http.get<Match[]>(`${UrlAddition}/playingday`, {
      params: { playingday_id },
    });
    console.log("Matches by playingday_id in service/match.tsx:", matches.data);
    if (matches.data){
      return matches.data
    }
    else{
      return [];
    }

  } catch (error: any) {
    console.error(error);
    return error.message;
  }
}


export async function getMatchById(match_id: number) {
  try {
    const foundMatch = await http.get<Match>(`${UrlAddition}/`, {
      params: { match_id },
    });
    console.log(
      "Match with match_id in service/match.tsx:",
      foundMatch.data
    );
    if (foundMatch.data) {
      return foundMatch.data;
    } else {
      return [];
    }
  } catch (error: any) {
    console.error(error);
    return error.message;
  }
}

export async function matchRegister(
  playingday_id: number,
  player1_id?: number,
  player2_id?: number,
  player1FramesWon?: number,
  player2FramesWon?: number
) {
  try {
    const token = localStorage.getItem("token");

    if (!token) {
      throw new Error("No JWT token found in localStorage");
    }

    const response = await http.post<MatchFormValues | any>(
      `${UrlAddition}/create`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          playingday_id: playingday_id,
          player1_id: player1_id,
          player2_id: player2_id,
          player1FramesWon: player1FramesWon,
          player2FramesWon: player2FramesWon,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error(error);
    throw Error(error.message);
  }
}

export async function matchUpdate(
  match_id: number,
  player1_id?: number,
  player2_id?: number,
  player1FramesWon?: number,
  player2FramesWon?: number,
  playinday_id?: number
) {
  try{
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("No JWT token found in localStorage");
    }
    const response = await http.put<MatchFormValues | any>(
      `${UrlAddition}/update`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          match_id: match_id,
          player1_id: player1_id,
          player2_id: player2_id,
          player1FramesWon: player1FramesWon,
          player2FramesWon: player2FramesWon,
          playingday_id: playinday_id,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error(error);
    throw Error(error.message);
  }
  }