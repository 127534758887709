import {
  Flex,
  Text,
  HStack,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  useDisclosure,
  Link,
} from "@chakra-ui/react";
import Fontsize from "../../stylingObjects/FontSize";
import { IoMdMenu } from "react-icons/io";
import React from "react";
import {useNavigate} from "react-router";
import Health from './Health';


export default function Menu() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  function handleClickNavigate(pathname: string){
    navigate(pathname);
  }
  function handleClickLogout(){
    localStorage.removeItem("User");
    localStorage.removeItem("token");
    window.location.reload();
    navigate("/")
    
  }

  //Home - Competities - Users - Login - Register(nog niet erin steken) - Reglement
  return (
    <>
      <Flex bgColor="green" py="1" align="center" justify="space-between">
      {/* <Text display={{base:"flex", sm:"none"}}> BASE </Text>
        <Text display={{base:"none", sm:"flex", md:"none"}}> SM </Text>
        <Text display={{base:"none", sm:"none", md:"flex", lg:"none"}}> MD </Text>
        <Text display={{base:"none", sm:"none", md:"none", lg:"flex", xl:"none"}}> LG </Text>
        <Text display={{base:"none", sm:"none", md:"none", lg:"none", xl:"flex"}}> XL </Text> */}
        <Health/>
        <Text
          m="0 auto"
          fontSize={{ ...Fontsize.title }}
          color="blackAlpha.800"
        >
          B.C. De Scheve Stoot
        </Text>
        {/*Mobile menu*/}
        <Flex
          h="20px"
          px="6"
          py="5"
          align="center"
          justify="space-between"
          display={{ base: "flex", lg: "none" }}
        >
          <Button
            ref={btnRef}
            onClick={onOpen}
            size={{ base: "sm" }}
          >
            <IoMdMenu size="30px"/>
          </Button>
          <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent bgColor="green">
              <DrawerCloseButton />
              <DrawerHeader>
                <Text color="blackAlpha.900">Menu</Text>
              </DrawerHeader>

              <DrawerBody>
                <VStack alignItems="start">
                  <Button h={{ base: "30px", lg: "40px" }} onClick={() => {handleClickNavigate("/"); onClose()}}>Home</Button>
                  <Button h={{ base: "30px", lg: "40px" }} onClick={() => {handleClickNavigate("/tournament"); onClose()}}>Toernooi</Button>
                  <Button h={{ base: "30px", lg: "40px" }} onClick={() => {handleClickNavigate("/competities"); onClose()}}>Competities</Button>
                  <Button h={{ base: "30px", lg: "40px" }} onClick={() => {handleClickNavigate("/halloffame"); onClose()}}>Winnaars</Button>

                  <Button h={{ base: "30px", lg: "40px" }}><Link href="/assets/files/DeScheveStoot_regels.pdf" target={"_blank"}>Reglement</Link></Button>
                  <Button h={{ base: "30px", lg: "40px" }} onClick={() => {handleClickNavigate("/aanmelden"); onClose()}} display={localStorage.getItem("User")? "none" : "block"}>Aanmelden</Button>
                  <Button h={{ base: "30px", lg: "40px" }} onClick={() => {handleClickLogout(); onClose()}} display={localStorage.getItem("User")? "block" : "none"}>Afmelden</Button>
                </VStack>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Flex>
        {/* Desktop menu */}
      </Flex>
      <Flex
        h={{ base: "35px", lg: "50px" }}
        px="6"
        py="5"
        align="center"
        justify="space-between"
        bgColor="green"
        borderRadius="0px 0px 20px 20px"
        display={{ base: "none", lg: "flex" }}
      >
        <HStack as="nav" spacing="5" m="0 auto">
          <Button h={{ base: "30px", lg: "40px" }} onClick={() => handleClickNavigate("/")}>Home</Button>
          <Button h={{ base: "30px", lg: "40px" }} onClick={() => handleClickNavigate("/tournament")}>Toernooi</Button>
          <Button h={{ base: "30px", lg: "40px" }} onClick={() => handleClickNavigate("/competities")}>Competities</Button>
          <Button h={{ base: "30px", lg: "40px" }} onClick={() => handleClickNavigate("/halloffame")}>Winnaars</Button>

          <Link href="/assets/files/DeScheveStoot_regels.pdf" target={"_blank"}><Button h={{ base: "30px", lg: "40px" }}>Reglement</Button></Link>
          <Button h={{ base: "30px", lg: "40px" }} onClick={() => handleClickNavigate("/aanmelden")} display={localStorage.getItem("User")? "none" : "block"}>Aanmelden</Button>
          <Button h={{ base: "30px", lg: "40px" }} onClick={() => handleClickLogout()} display={localStorage.getItem("User")? "block" : "none"}>Afmelden</Button>
        </HStack>
      </Flex>
    </>
  );
}
