import {
  Select,
  Text,
  Spinner,
  Center,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  Box,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { getPlayingdaysFromSeason } from "../../service/playingday";
import { getPlayerStatsFromSeason } from "../../service/season";
import FontSize from "../../stylingObjects/FontSize";
import Player from "../../type/Player";
import Playingday from "../../type/Playingday";
import Matches from "./Matches";
import { boolean } from "yup";

interface Props {
  seasonId: number | null;
}

export const PlayerStats: React.FC<Props> = ({ seasonId }) => {
  const [playingdays, setPlayingdays] = useState<Playingday[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedPlayingdayId, setSelectedPlayingdayId] = useState<
    number | null
  >(null);
  const [players, setPlayers] = useState<Player[]>([]);

  const fetchPlayerStats = useCallback(async () => {
    try {
      setLoading(true);
      if (seasonId) {
        const fetchedPlayers: Player[] = await getPlayerStatsFromSeason(
          seasonId
        );
        setPlayers(fetchedPlayers);
        console.log(
          "Player statistics data in fetchPlayerStatistics-function:",
          players
        );
      } else {
        setPlayers([]);
      }
    } catch (error: any) {
      console.error("Error fetching player statistics:", error.message);
    } finally {
      setLoading(false);
    }
  }, [seasonId]);

  useEffect(() => {
    fetchPlayerStats();
  }, [fetchPlayerStats]);

  // Function to split the players array into two halves
  const splitPlayersArray = (players: Player[]) => {
    const midIndex = Math.ceil(players.length / 2);
    return [players.slice(0, midIndex), players.slice(midIndex)];
  };

  const [leftColumnPlayers, rightColumnPlayers] = splitPlayersArray(players);

  // Component to render a green line
  const GreenLine = () => <Box w="full" h="2px" bg="green.500"/>;

  // Component to render a player ranking table with a given title
  const RankingTable = ({
    players,
    title,
  }: {
    players: Player[];
    title: string;
  }) => (
    <VStack spacing={4} align="stretch">
      <Text color="green" fontSize={{ ...FontSize.subTitle }}>
        {title}
      </Text>
      <Table color="green" fontSize={{ ...FontSize.header }} size="sm">
        <Thead>
          <Tr>
            <Th>
              <Center>
                <Text color="green" fontSize={{ ...FontSize.header }}>
                  Rank
                </Text>
              </Center>
            </Th>
            <Th>
              <Center>
                <Text color="green" fontSize={{ ...FontSize.header }}>
                  Name
                </Text>
              </Center>
            </Th>
            <Th>
              <Center>
                <Text color="green" fontSize={{ ...FontSize.header }}>
                  Frames won
                </Text>
              </Center>
            </Th>
            <Th>
              <Center>
                <Text color="green" fontSize={{ ...FontSize.header }}>
                  Frames lost
                </Text>
              </Center>
            </Th>
            <Th>
              <Center>
                <Text color="Green" fontSize={{ ...FontSize.header }}>
                  Matches won
                </Text>
              </Center>
            </Th>
            <Th>
              <Center>
                <Text color="Green" fontSize={{ ...FontSize.header }}>
                  Matches played
                </Text>
              </Center>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {players.map((player) => (
            <Tr key={player.player_id}>
              <Td>
                <Center>
                  <Text
                    fontSize={{ ...FontSize.header }}
                    fontFamily={"Arial"}
                    fontWeight={900}
                  >
                    {player.rank}
                  </Text>
                </Center>
              </Td>
              <Td>
                <Center>
                  <Text
                    fontSize={{ ...FontSize.header }}
                    fontFamily={"Arial"}
                    fontWeight={900}
                  >
                    {player.name}
                  </Text>
                </Center>
              </Td>
              <Td>
                <Center>
                  <Text
                    fontSize={{ ...FontSize.header }}
                    fontFamily={"Arial"}
                    fontWeight={900}
                  >
                    {player.total_frames_won}
                  </Text>
                </Center>
              </Td>
              <Td>
                <Center>
                  <Text
                    fontSize={{ ...FontSize.header }}
                    fontFamily={"Arial"}
                    fontWeight={900}
                  >
                    {player.total_frames_lost}
                  </Text>
                </Center>
              </Td>
              <Td>
                <Center>
                  <Text
                    fontSize={{ ...FontSize.header }}
                    fontFamily={"Arial"}
                    fontWeight={900}
                  >
                    {player.total_matches_won}
                  </Text>
                </Center>
              </Td>
              <Td>
                <Center>
                  <Text
                    fontSize={{ ...FontSize.header }}
                    fontFamily={"Arial"}
                    fontWeight={900}
                  >
                    {player.total_matches_played}
                  </Text>
                </Center>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </VStack>
  );

  return (
    <VStack spacing={4}>
      <Text color="green" fontSize={{ ...FontSize.footer }}>
        Rangschikking van de spelers
      </Text>
      <Text color="green" fontSize={{ ...FontSize.header }} fontStyle="italic">
        (Enkel afgeronde speeldagen worden meegerekend)
      </Text>
      {loading && <Spinner color="red" />}
      {players.length > 0 && (
        <>
          <RankingTable players={leftColumnPlayers} title="Eindronde 1" />
          <GreenLine />
          <RankingTable players={rightColumnPlayers} title="Eindronde 2" />
        </>
      )}
      {players.length === 0 && (
        <Text color="red" fontFamily={"Arial"} fontWeight={900}>
          Geen spelers gevonden
        </Text>
      )}
    </VStack>
  );
};
