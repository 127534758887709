import http from "./http";

const UrlAddition: string = `/tournament`;

type TournamentInfo = {
  tournament_players_already_registered: number;
  tournament_max_players: number;
};

// Create a new type that includes the "response" field
type TournamentInfoResponse = {
  response: TournamentInfo;
};

export async function getTournamentRegisteredPlayersCount(tournament_id: number) {
  try {
    const response = await http.get<TournamentInfoResponse>(`${UrlAddition}/numberofplayers`, {
      params: { tournament_id: tournament_id },
    });
    if (response.status === 200) {
      //console.log("TournamentInfo in service/tournament.tsx:", response.data);
      return response.data.response; // Access the "response" field of the data
    } else {
      throw response;
    }
  } catch (error: any) {
    console.error(error.response);
    throw error.response;
  }
}
